/* Footer.css */

/* Footer Container */
.dashboard-footer {
  background-color: var(--footer-background);
  color: white;
  text-align: center;
  padding: 20px 30px;
  box-shadow: 0 -4px 8px var(--shadow-color);
  transition: all 0.3s ease;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Footer Content */
.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 10px 0;
}

/* Footer Navigation */
.footer-nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  color: black;
}

.footer-nav a {
  color: var(--warning-color);
  text-decoration: none;
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-nav a:hover {
  background-color: var(--secondary-color);
  color: white;
}

.footer-nav a svg {
  color: var(--warning-color);
  transition: color 0.3s ease;
}

.footer-nav a:hover svg {
  color: white;
}

/* Ensure the footer stays at the bottom on short pages */
html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.project-detail-container {
  flex-grow: 1;
  width: 100%;
}

/* Responsive Design for Footer */
@media (max-width: 768px) {
  .footer-content {
    gap: 15px;
  }

  .footer-nav {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .footer-content {
    gap: 10px;
  }

  .footer-nav {
    flex-direction: column;
    gap: 10px;
  }
}
