/* src/CSS/LandingPage.css */

/* Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    scroll-behavior: smooth;
    background-color: #ffffff;
}

/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar Logo Styles */
.navbar-logo {
    display: inline-flex;
    align-items: center;
}

.logo-image {
    height: 60px; /* Adjust the height as needed */
    width: auto;  /* Maintains aspect ratio */
    transition: opacity 0.3s ease;
}

.logo-image:hover {
    opacity: 0.8;
}

/* Hamburger Menu Styles */
.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.hamburger:focus {
    outline: none;
}

.hamburger-line {
    width: 100%;
    height: 3px;
    background-color: #333;
    border-radius: 2px;
    transition: all 0.3s ease;
}

.hamburger.active .hamburger-line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active .hamburger-line:nth-child(2) {
    opacity: 0;
}

.hamburger.active .hamburger-line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

/* Navbar Links Styles */
.navbar-links {
    display: flex;
    align-items: center;  /* Ensures the links are vertically aligned */
}

.navbar-links ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navbar-links ul li {
    display: flex;
    align-items: center;
}

.navbar-links ul li a,
.navbar-links ul li button.navbar-button {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    transition: color 0.3s ease, background-color 0.3s ease;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
}

.navbar-links ul li a:hover,
.navbar-links ul li button.navbar-button:hover {
    color: #007bff;
    background-color: rgba(0, 123, 255, 0.1);
}

.navbar-button {
    font-family: inherit;
}

/* Hero Section */
.hero-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px;
    background-color: #f9f9f9;
}

.hero-content {
    flex: 1 1 50%;
    padding: 20px;
}

.hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
    color: #333;
}

.hero-content p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
}

.cta-button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}

.hero-image {
    flex: 1 1 50%;
    text-align: center;
    padding: 20px;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Features Section */
.features-section {
    padding: 60px;
    background-color: #fff;
}

.features-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #007bff;
}

.features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
}

.feature {
    width: 45%;
    text-align: center;
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center horizontally */
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff; /* Optional: Add background color for better visibility */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
}

.feature img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain; /* Maintain aspect ratio */
}

.feature h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.feature p {
    font-size: 16px;
    color: #666;
}

/* Benefits Section */
.benefits-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
}

.benefits-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #007bff;
}

.benefits-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Reduced gap from 40px to 20px */
}

.benefits-content, /* Text content first */
.benefits-image { /* Image second */
    flex: 1 1 400px;
}

.benefits-content {
    /* Ensures text is aligned to the left */
    text-align: left;
}

.benefits-content p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
}

.benefits-content ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.benefits-content ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.benefits-content ul li::before {
    content: '✔️';
    margin-right: 10px;
    color: #28a745;
}

.benefits-content .cta-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.benefits-content .cta-button:hover {
    background-color: #218838;
}

.benefits-image img {
    width: 70%; /* Adjust this percentage as needed */
    max-width: 400px; /* Maximum size to control large screens */
    border-radius: 8px;
    display: block;
    margin: 0 auto; /* Center the image horizontally */
}

/* Canvas Showcase Section */
.canvas-showcase {
    padding: 60px;
    background-color: #f0f4f8;
}

.canvas-showcase h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #007bff;
}

.canvas-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.canvas-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: block;
    margin: 0 auto; /* Center the image horizontally */
}

.canvas-description {
    flex: 1 1 40%;
    padding: 20px;
}

.canvas-description p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #555;
}

.canvas-description .cta-button {
    background-color: #28a745;
}

.canvas-description .cta-button:hover {
    background-color: #218838;
}

/* How It Works Section */
.how-it-works-section {
    padding: 60px;
    background-color: #f9f9f9;
}

.how-it-works-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #007bff;
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
}

.step {
    width: 30%;
    text-align: center;
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center horizontally */
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff; /* Optional: Add background color for better visibility */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
}

.step img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain; /* Maintain aspect ratio */
}

.step h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
}

.step p {
    font-size: 16px;
    color: #666;
}

/* Testimonials Section */
.testimonials-section {
    padding: 60px;
    background-color: #fff;
}

.testimonials-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #007bff;
}

.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
}

.testimonial {
    width: 45%;
    text-align: center;
}

.testimonial img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.testimonial p {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 10px;
    color: #555;
}

.testimonial h4 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
}

.testimonial h5 {
    font-size: 16px;
    color: #777;
}

/* Call-to-Action Banner */
.cta-banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px;
    background-color: #007bff;
    color: #fff;
}

.cta-banner h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.cta-content {
    flex: 1 1 50%;
    padding: 20px;
}

.cta-content h2 {
    margin-bottom: 20px;
}

.cta-content button {
    padding: 15px 30px;
    background-color: #fff;
    color: #007bff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-content button:hover {
    background-color: #e6e6e6;
}

.cta-image {
    flex: 1 1 50%;
    text-align: center;
    padding: 20px;
}

.cta-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    display: block;
    margin: 0 auto; /* Center the image horizontally */
}

/* Footer Styles */
.footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
}

.footer-content {
    text-align: center;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 992px) {
    .feature, .step, .testimonial {
        width: 45%;
    }

    .hero-section, .canvas-showcase, .cta-banner, .benefits-container, .steps-container, .testimonials-container, .features-container {
        flex-direction: column;
    }

    .hero-content, .hero-image,
    .canvas-content .canvas-image, .canvas-content .canvas-description,
    .cta-banner .cta-content, .cta-banner .cta-image,
    .benefits-container, .steps-container, .testimonials-container, .features-container {
        flex: 1 1 100%;
        padding: 10px;
    }

    .canvas-content {
        flex-direction: column;
    }

    /* Adjust logo size for medium screens */
    .logo-image {
        height: 40px;
    }

    /* Adjust Benefits Section */
    .benefits-container {
        gap: 20px; /* Ensure the gap is maintained */
    }

    /* Align text to center on medium screens if desired */
    .benefits-content {
        text-align: center;
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    .cta-button {
        width: 100%;
        padding: 15px;
        font-size: 16px;
    }

    .feature, .step, .testimonial {
        width: 100%;
    }

    .navbar-links {
        position: absolute;
        top: 80px; /* Adjust based on navbar height */
        left: 0;
        width: 100%;
        background-color: #fff;
        flex-direction: column;
        align-items: flex-start;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
    }

    .navbar-links.active {
        max-height: 500px; /* Adjust as needed */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-links ul {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .navbar-links ul li {
        width: 100%;
    }

    .navbar-links ul li a,
    .navbar-links ul li button.navbar-button {
        width: 100%;
        padding: 15px 20px;
        border-bottom: 1px solid #f0f0f0;
    }

    .navbar-links ul li a:hover,
    .navbar-links ul li button.navbar-button:hover {
        background-color: rgba(0, 123, 255, 0.1);
        color: #007bff;
    }

    /* Hamburger Menu Display */
    .hamburger {
        display: flex;
    }

    /* Benefits Section */
    .benefits-section {
        padding: 40px 10px;
    }

    .benefits-content ul li {
        font-size: 0.9rem;
    }

    .benefits-content .cta-button {
        font-size: 0.9rem;
        padding: 10px 15px;
    }

    /* Adjust logo size for small screens */
    .logo-image {
        height: 35px;
    }

    /* Stack Benefits Content and Image vertically on small screens */
    .benefits-container {
        flex-direction: column;
        align-items: center;
    }

    .benefits-content {
        text-align: center;
    }

    /* Center the image in Canvas Showcase on small screens */
    .canvas-image img {
        margin-bottom: 20px;
    }

    /* Adjust Steps Container */
    .steps-container {
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .hero-content h1 {
        font-size: 28px;
    }

    .features-section h2,
    .benefits-section h2,
    .canvas-showcase h2,
    .how-it-works-section h2,
    .cta-banner h2 {
        font-size: 24px;
    }

    .cta-banner h2 {
        font-size: 28px;
    }

    .step img {
        width: 80px;
        height: 80px;
    }

    .cta-content h2 {
        font-size: 24px;
    }

    .cta-content button {
        font-size: 16px;
    }
}
.Alpha-tag {
    display: inline-block;
    background-color: #ffc107; /* A vibrant yellow color */
    color: #fff;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 8px;
    font-weight: bold;
}
