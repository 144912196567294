/* Flow Name Container */
.flow-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Flow Name Display */
  .flow-name-display {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  
  /* Edit Icon */
  .edit-icon {
    font-size: 18px;
    color: #007bff;
    margin-left: 10px;
    transition: color 0.3s ease;
  }
  
  .edit-icon:hover {
    color: #0056b3;
  }
  
  /* Flow Name Input */
  .flow-name-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .flow-name-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    outline: none;
  }
  