/* src/CSS/NewUserPage.css */

/* Root Variables for Consistent Styling */
:root {
  --primary-color: #2c3e50; /* Dark Blue for Text */
  --secondary-color: #2980b9; /* Blue for Links */
  --background-color: #f9f9f9; /* Light Gray Background */
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --max-width: 500px;
  --heading-color: #2c3e50;
  --link-hover-color: #1abc9c;
  --error-color: #e74c3c; /* Red for Errors */
  --success-color: #27ae60; /* Green for Success */
  --divider-color: #bdc3c7;
  --input-border-color: #ccc;
  --input-focus-border-color: var(--secondary-color);
  --input-invalid-border-color: var(--error-color);
  --submit-button-hover-color: #1abc9c;
  --privacy-label-color: #555555;
  --valid-input-border-color: var(--success-color);
  --valid-input-shadow-color: rgba(39, 174, 96, 0.5);
}

/* Container for New User Form */
.new-user-container {
  max-width: var(--max-width);
  margin: 50px auto;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: var(--font-family);
}

.new-user-form h2 {
  text-align: center;
  color: var(--heading-color);
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Form Group */
.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

/* Labels */
.new-user-form label {
  margin-bottom: 5px;
  color: var(--primary-color);
  font-weight: 500;
}

/* Inputs */
.new-user-form input[type="text"],
.new-user-form input[type="email"],
.new-user-form input[type="password"] {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.new-user-form input[type="text"]:focus,
.new-user-form input[type="email"]:focus,
.new-user-form input[type="password"]:focus {
  border-color: var(--input-focus-border-color);
  outline: none;
  box-shadow: 0 0 5px rgba(41, 128, 185, 0.5);
}

/* Highlight Invalid Inputs */
.new-user-form input[aria-invalid="true"] {
  border-color: var(--input-invalid-border-color);
  box-shadow: 0 0 5px rgba(231, 76, 60, 0.5);
}

/* Highlight Valid Inputs (Optional) */
.new-user-form input[aria-invalid="false"] {
  border-color: var(--valid-input-border-color);
  box-shadow: 0 0 5px var(--valid-input-shadow-color);
}

/* reCAPTCHA */
.new-user-form .g-recaptcha {
  margin-bottom: 15px;
}

/* Privacy Policy Agreement */
.privacy-policy-agreement {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.privacy-policy-agreement input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: var(--secondary-color); /* Enhanced Checkbox Styling */
}

.privacy-policy-agreement label {
  font-size: 0.95rem;
  color: var(--privacy-label-color);
  cursor: pointer;
}

.privacy-policy-agreement .privacy-link {
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.privacy-policy-agreement .privacy-link:hover,
.privacy-policy-agreement .privacy-link:focus {
  color: var(--link-hover-color);
  text-decoration: underline;
}

.privacy-policy-agreement .privacy-link:focus {
  outline: 2px dashed var(--secondary-color);
  outline-offset: 4px;
}

/* Submit Button */
.submit-button {
  width: 100%;
  padding: 12px 20px;
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover,
.submit-button:focus {
  background-color: var(--submit-button-hover-color);
  outline: none;
}

.submit-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* Spinner (Optional) */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: var(--secondary-color);
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Messages */
.error-message {
  color: var(--error-color);
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

.success-message {
  color: var(--success-color);
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
}

/* Accessibility Enhancements */
.error-message:focus,
.success-message:focus {
  outline: none;
}

/* Responsive Design */
@media (max-width: 600px) {
  .new-user-container {
    padding: 15px 20px;
    margin: 30px 10px;
  }

  .new-user-form h2 {
    font-size: 1.5rem;
  }

  .privacy-policy-agreement label {
    font-size: 0.9rem;
  }

  .submit-button {
    padding: 10px 16px;
    font-size: 0.95rem;
  }
}
