/* src/CSS/ProjectDetail.css */

/* -------------------------------
   CSS Variables for Consistent Styling
-------------------------------- */
:root {
  /* Color Palette */
  --color-primary: #4a90e2;       /* Soft Blue */
  --color-secondary: #50e3c2;     /* Mint Green */
  --color-danger: #e74c3c;        /* Red */
  --color-warning: #f1c40f;       /* Yellow */
  --color-success: #2ecc71;       /* Green */
  --color-muted: #7f8c8d;         /* Gray */
  --color-light: #ecf0f1;         /* Light Gray */
  --color-dark: #2c3e50;          /* Dark Blue */
  --color-white: #ffffff;         /* White */
  --color-overlay: rgba(0, 0, 0, 0.5); /* Overlay Color */

  /* Typography */
  --font-family: 'Roboto', sans-serif;
  --font-size-small: 0.875rem; /* 14px */
  --font-size-medium: 1rem;    /* 16px */
  --font-size-large: 1.25rem;  /* 20px */
  --font-size-xlarge: 2rem;    /* 32px */
  --font-weight-normal: 400;
  --font-weight-bold: 700;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  /* Box Shadows */
  --shadow-light: 0 2px 8px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 4px 12px rgba(0, 0, 0, 0.15);
  --shadow-dark: 0 6px 20px rgba(0, 0, 0, 0.2);
}

/* Global Styles */
body {
  font-family: var(--font-family);
  background-color: var(--color-light);
  margin: 0;
  padding: 0;
  color: var(--color-dark);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: var(--font-weight-bold);
}

p {
  margin: 0;
  line-height: 1.6;
  color: var(--color-muted);
}

/* -------------------------------
   Project Detail Container
-------------------------------- */
.project-detail-container {
  width: 100%;
  padding: var(--spacing-md);
  padding-top: calc(var(--spacing-md) + 60px); /* Adjust based on header height */
  background-color: var(--color-white);
  box-sizing: border-box;
}

/* -------------------------------
   Project Header
-------------------------------- */
.project-header {
  margin-bottom: var(--spacing-md);
}

.project-title-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.project-title-actions h1 {
  font-size: var(--font-size-large);
  color: var(--color-dark);
  margin: 0;
}

.project-description {
  font-size: var(--font-size-small);
  color: var(--color-muted);
  margin-bottom: var(--spacing-md);
}

/* Project Actions */
.project-actions {
  display: flex;
  gap: var(--spacing-xs);
}

.action-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--color-white);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.action-button svg {
  margin-right: var(--spacing-xs);
  font-size: 1rem;
}

.edit-project-button {
  background-color: var(--color-primary);
}

.edit-project-button:hover {
  background-color: #3a7dc6;
}

.delete-project-button {
  background-color: var(--color-danger);
}

.delete-project-button:hover {
  background-color: #c0392b;
}

/* -------------------------------
   Canvas Section
-------------------------------- */
.canvas-section {
  margin-top: var(--spacing-lg);
}

.canvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
}

.canvas-header h2 {
  font-size: var(--font-size-medium);
  color: var(--color-dark);
  margin: 0;
}

.new-canvas-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--color-secondary);
  color: var(--color-white);
  transition: background-color 0.3s ease;
}

.new-canvas-button svg {
  margin-right: var(--spacing-xs);
  font-size: 1rem;
}

.new-canvas-button:hover {
  background-color: #45b3b0;
}

/* Canvas Grid */
.canvas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

/* Canvas Card */
.canvas-card {
  background-color: var(--color-white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.canvas-card:hover {
  box-shadow: var(--shadow-medium);
  transform: translateY(-2px);
}

.canvas-card h3 {
  font-size: var(--font-size-medium);
  color: var(--color-dark);
  margin-bottom: var(--spacing-sm);
}

/* Canvas Actions */
.canvas-actions {
  display: flex;
  gap: var(--spacing-sm);
  justify-content: flex-end;
  margin-top: auto;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: transform 0.2s ease, opacity 0.2s ease;
  color: var(--color-muted);
}

.icon-button:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.view {
  color: #3498db;
}

.edit {
  color: #f1c40f;
}

.delete {
  color: var(--color-danger);
}

/* No Canvases Message */
.no-canvases-message {
  font-size: var(--font-size-medium);
  color: var(--color-muted);
  margin-top: var(--spacing-md);
}

/* -------------------------------
   Modals
-------------------------------- */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  text-align: center;
  box-shadow: var(--shadow-dark);
  max-width: 500px;
  width: 90%;
  animation: fadeIn 0.3s ease;
}

.modal-content h2 {
  font-size: var(--font-size-large);
  color: var(--color-dark);
  margin-bottom: var(--spacing-md);
}

.modal-content p {
  font-size: var(--font-size-medium);
  color: var(--color-dark);
  margin-bottom: var(--spacing-lg);
}

.modal-content form label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: var(--font-weight-bold);
  color: var(--color-dark);
  text-align: left;
}

.modal-content form input,
.modal-content form textarea {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-muted);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-medium);
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  margin-bottom: var(--spacing-md);
}

.modal-content form input:focus,
.modal-content form textarea:focus {
  border-color: var(--color-primary);
  outline: none;
}

.modal-content form textarea {
  resize: vertical;
  min-height: 100px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
}

.confirm-button,
.cancel-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  color: var(--color-white);
  transition: background-color 0.3s ease;
}

.confirm-button {
  background-color: var(--color-danger);
}

.confirm-button:hover {
  background-color: #c0392b;
}

.cancel-button {
  background-color: var(--color-muted);
}

.cancel-button:hover {
  background-color: #95a5a6;
}

/* -------------------------------
   Animations
-------------------------------- */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* -------------------------------
   Responsive Design
-------------------------------- */
@media (max-width: 600px) {
  .project-title-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }

  .project-actions {
    justify-content: flex-start;
  }

  .confirmation-actions,
  .modal-actions {
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .confirm-button,
  .cancel-button {
    flex: none;
    width: 100%;
  }

  .canvas-actions {
    justify-content: space-between;
  }
}

/* -------------------------------
   Loading and Error States
-------------------------------- */
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: var(--color-danger);
  font-size: var(--font-size-medium);
}

/* -------------------------------
   Footer Styles
-------------------------------- */
footer {
  margin-top: var(--spacing-lg);
  padding: var(--spacing-md);
  background-color: var(--color-light);
  text-align: center;
  color: var(--color-dark);
}
