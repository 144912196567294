/* Dropzone styling */
.dropzone {
  width: 100%;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  text-align: center;
  background-color: #fafafa;
  cursor: pointer;
  margin-bottom: 20px;
  transition: border-color 0.3s, background-color 0.3s;
}

.dropzone:hover {
  border-color: #888;
  background-color: #f0f0f0;
}

/* Field display styling */
.field-display {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between fields */
  max-height: none; /* Remove height restriction */
  overflow-y: visible; /* Allow content to grow without scrollbars */
  padding-right: 10px;
  flex: 1;
}

.field-display::-webkit-scrollbar {
  width: 8px;
}

.field-display::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* Individual field styling */
.field {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separate name and button */
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.field:hover {
  background-color: #f5f5f5;
}

.field span {
  flex-grow: 1; /* Name takes up available space */
  margin-right: 10px; /* Space between name and button */
}

/* Adjust the flex properties */
#inputOutputSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  position: relative;
  gap: 20px;
}

#inputSection,
#outputSection {
  flex: 1; /* Allow sections to grow equally */

  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: visible; /* Allow lines to be visible */
  height: auto;
  position: relative;
}

#outputSection {
  margin-left: 10px;
}

/* Operations section styling */
.operations-section {
  flex: 0 0 15%; /* Fixed width for operations */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: auto;
}

/* Operation block styling */
.operation-block {
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 8px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.operation-block:hover {
  background-color: #f0f8ff;
  border-color: #0056b3;
}

/* Button styling */
.btn {
  border: none;
  color: white;
  background-color: #4a5568;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  z-index: 200;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  background-color: #2d3748;
}

/* Eye Icon Styling */
.eye-icon {
  position: absolute;
  top: 8px;
  right: 30px; /* Adjusted to prevent overlap with operation indicator */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.eye-icon svg {
  width: 16px;
  height: 16px;
}

/* Context Menu Styles */
.context-menu {
  position: fixed;
  z-index: 1001; /* Ensure it's above modals and other elements */
  width: 150px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0;
}


.context-menu-item {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #f1f1f1;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Below context menu */
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Transformation Editor */
.transformation-editor textarea {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
  font-family: monospace;
  width: 100%;
  resize: vertical;
}

.transformation-editor button {
  padding: 8px 12px;
  margin-top: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.transformation-editor button:hover {
  background-color: #0056b3;
}

/* Add Operation Modal */
.modal-content input[type='text'] {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
  font-family: sans-serif;
  width: calc(100% - 120px);
  margin-right: 10px;
}

.modal-content button {
  padding: 8px 12px;
  margin-top: 10px;
  border: none;
  background-color: #28a745;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #218838;
}

/* Output Preview */
.output-preview {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  max-width: 90%;
  max-height: 50%;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1002; /* Above context menu */
}

.output-preview pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.output-preview button {
  padding: 8px 12px;
  margin-top: 10px;
  border: none;
  background-color: #dc3545;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.output-preview button:hover {
  background-color: #c82333;
}

/* Common operations */
.common-operations {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.common-operation-button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.common-operation-button:hover {
  background-color: #0056b3;
}

/* Custom Operation Input */
.custom-operation {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.custom-operation input[type='text'] {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.custom-operation button {
  padding: 8px 12px;
  border: none;
  background-color: #28a745;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-operation button:hover {
  background-color: #218838;
}

/* Operations List */
.operations-list {
  margin-top: 20px;
}

.operations-list ul {
  list-style-type: disc;
  padding-left: 20px;
}

.operations-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.remove-operation-button {
  background: none;
  border: none;
  color: red;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.remove-operation-button:hover {
  color: darkred;
}

/* Example Data Section */
.example-data {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.example-data h3 {
  margin-bottom: 20px;
  color: #343a40;
}

.data-section {
  display: flex;
  gap: 20px;
}

.input-data,
.output-data {
  flex: 1;
}

.input-data pre,
.output-data pre {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}
