/* src/CSS/Header.css */

/* Define Variables for a Light Grey Color Palette */
:root {
    --primary-color: #f0f0f0;          /* Light grey for the header background */
    --secondary-color: #ffffff;        /* White for accents */
    --text-color: #333333;             /* Dark grey for text */
    --icon-color: #555555;             /* Medium grey for icons */
    --hover-bg-color: #e0e0e0;         /* Slightly darker grey for hover effects */
    --dropdown-bg-color: #ffffff;      /* White for dropdown background */
    --shadow-color: rgba(0, 0, 0, 0.1);/* Soft shadow for depth */
    --border-color: #d9d9d9;           /* Light grey for borders */
  }
  
  /* Header Container */
  .header {
    background-color: var(--primary-color);
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    box-shadow: 0 2px 4px var(--shadow-color);
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .logo-image {
    height: 77px;
    width: auto;
  }
  
  .logo-text {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
  }
  
  .header-nav {
    display: flex;
    align-items: center;
  }
  
  .header-nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .header-nav ul li {
    position: relative;
  }
  
  .header-nav ul li button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
  
  .header-nav ul li button:hover {
    background-color: var(--hover-bg-color);
  }
  
  .header-nav ul li button svg {
    color: var(--icon-color);
  }
  
  .user-actions {
    position: relative; /* For dropdown positioning */
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
    gap: 5px;
  }
  
  .user-menu svg {
    color: var(--icon-color);
  }
  
  /* Dropdown Menu Styling */
  .dropdown-menu {
    position: absolute;
    top: 110%;
    right: 0;
    background-color: var(--dropdown-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 4px 6px var(--shadow-color);
    z-index: 200;
    min-width: 180px;
    overflow: hidden;
    animation: fadeIn 0.3s ease;
  }
  
  .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-menu li {
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--text-color);
    transition: background-color 0.3s ease;
  }
  
  .dropdown-menu li:hover {
    background-color: var(--hover-bg-color);
  }
  
  .dropdown-menu li svg {
    margin-right: 8px;
    color: var(--icon-color);
  }
  
  /* Animation for Dropdown */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Menu Toggle Button for Mobile */
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--icon-color);
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .header-nav {
      position: fixed;
      top: 0;
      left: -100%;
      width: 250px;
      height: 100vh;
      background-color: var(--primary-color);
      flex-direction: column;
      padding-top: 60px;
      transition: left 0.3s ease;
      box-shadow: 2px 0 5px var(--shadow-color);
    }
  
    .header-nav.open {
      left: 0;
    }
  
    .header-nav ul {
      flex-direction: column;
      gap: 15px;
    }
  
    .user-actions {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      width: 100%;
    }
  
    .dropdown-menu {
      position: relative;
      top: 0;
      right: 0;
      box-shadow: none;
      border: none;
    }
  
    .menu-toggle {
      display: block;
    }
  }
  