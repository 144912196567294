.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .login-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  
  .login-message {
    margin-top: 10px;
    color: red;
  }
  