body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
}

/* Header styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #007bff;
  color: #ffffff;
}

header .logo {
  font-size: 24px;
  font-weight: bold;
}

header .menu-button {
  background-color: #ffffff;
  color: #2d3748;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

header .menu-button:hover {
  background-color: #f0f0f0;
}

/* Dark mode base styles */
body.dark-mode, .app.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.header.dark-mode {
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.menu-button, .toggle-dark-mode {
  background-color: #333;
  color: #e0e0e0;
  border: none;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.menu-button:hover, .toggle-dark-mode:hover {
  background-color: #444;
}

.reset-button.dark-mode {
  background-color: #444;
  color: #e0e0e0;
  border: none;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}

.reset-button.dark-mode:hover {
  background-color: #555;
}

.operations-section.dark-mode,
#inputSection.dark-mode,
#outputSection.dark-mode {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.file-label.dark-mode {
  color: #e0e0e0;
  font-weight: bold;
}

.operation-block.dark-mode {
  background-color: #333;
  color: #e0e0e0;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.operation-block.dark-mode:hover {
  background-color: #444;
}

.field-display.dark-mode .field {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.blue-text.dark-mode {
  color: #2196f3;
  font-weight: bold;
}



.field.dark-mode {
  background-color: #333;
  border: 1px solid #444;
  color: #e0e0e0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

SVG Styling
svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none; 
}
.mapping-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}


/* App container styling */
.app {
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto;

  position: relative;
  overflow: visible; /* Ensure SVG lines aren't clipped */
}

button {
  display: inline-block;
  margin-top: 20px;
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color:#2d3748;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

