/* src/CSS/ProfilePage.css */

.profile-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .profile-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-message {
    color: green;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .profile-details p {
    font-size: 1.1em;
    margin: 10px 0;
  }
  
  .edit-button, .save-button, .cancel-button, .delete-button {
    padding: 10px 15px;
    margin: 10px 5px 0 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .save-button {
    background-color: #2196F3;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .delete-button {
    background-color: #e91e63;
    color: white;
    width: 100%;
    margin-top: 20px;
  }
  