/* src/CSS/PrivacyPolicyPage.css */

/* Root Variables for Consistent Styling */
:root {
  --primary-color: #2c3e50; /* Dark Blue for Text */
  --secondary-color: #2980b9; /* Blue for Links */
  --background-color: #f9f9f9; /* Light Gray Background */
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --max-width: 800px;
  --heading-color: #2c3e50;
  --link-hover-color: #1abc9c;
  --divider-color: #bdc3c7;
}

/* Global Styles */
body {
  background-color: var(--background-color);
  font-family: var(--font-family);
  color: var(--primary-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Container for Privacy Policy */
.privacy-policy-container {
  max-width: var(--max-width);
  margin: 50px auto;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Headings */
.privacy-policy-container h1 {
  text-align: center;
  color: var(--heading-color);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.privacy-policy-container h2 {
  color: var(--heading-color);
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--divider-color);
  padding-bottom: 5px;
}

.privacy-policy-container h3 {
  color: var(--heading-color);
  font-size: 1.4rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Paragraphs */
.privacy-policy-container p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555555;
}

/* Lists */
.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 15px;
}

.privacy-policy-container li {
  margin-bottom: 10px;
  color: #555555;
}

/* Links */
.privacy-policy-container a {
  color: blue;
  text-decoration: none;
  transition: color 0.3s ease;
}

.privacy-policy-container a:hover,
.privacy-policy-container a:focus {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* Horizontal Rule */
.privacy-policy-container hr {
  border: none;
  border-top: 1px solid var(--divider-color);
  margin: 30px 0;
}

/* Emphasized Text */
.privacy-policy-container em {
  font-style: italic;
  color: #777777;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 15px 20px;
    margin: 30px 10px;
  }

  .privacy-policy-container h1 {
    font-size: 2rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.6rem;
  }

  .privacy-policy-container h3 {
    font-size: 1.2rem;
  }

  .privacy-policy-container p,
  .privacy-policy-container li {
    font-size: 0.95rem;
  }
}

/* Accessibility Enhancements */
.privacy-policy-container a:focus {
  outline: 2px dashed var(--secondary-color);
  outline-offset: 4px;
}

/* Optional: Add Smooth Scrolling for Internal Links */
.privacy-policy-container a[href^="#"] {
  scroll-behavior: smooth;
}
