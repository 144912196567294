/* Dashboard.css */

/* CSS Variables */
:root {
  --color-primary: #2f80ed;
  --color-secondary: #27ae60;
  --color-accent: #f2994a;
  --color-danger: #eb5757;
  --color-background: #f9fafb;
  --color-card-background: #ffffff;
  --color-text: #333333;
  --color-text-muted: #828282;
  --color-border: #e0e0e0;
  --color-shadow: rgba(0, 0, 0, 0.05);
  --font-family: 'Roboto', sans-serif;
  --font-size-base: 16px;
  --transition-speed: 0.3s;
}

/* Reset and Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  background-color: var(--color-background);
  color: var(--color-text);
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: inherit;
  border: none;
  cursor: pointer;
  background: none;
  padding: 0;
}

button:focus, input:focus, select:focus, textarea:focus {
  outline: none;
}

/* Container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.dashboard-header {
  background-color: var(--color-card-background);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px var(--color-shadow);
  position: sticky;
  top: 0;
  z-index: 100;
}

.dashboard-header h1 {
  margin: 0;
  font-size: 1.75rem;
  color: var(--color-primary);
}

.dashboard-header .user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dashboard-header .logout-button {
  background-color: var(--color-danger);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color var(--transition-speed);
}

.dashboard-header .logout-button:hover {
  background-color: #c0392b;
}

/* Main Content */
.dashboard-main {
  flex: 1;
  padding: 2rem;
}

.dashboard-main h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--color-text);
}

/* Controls */
.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.controls > * {
  flex: 1 1 200px;
  min-width: 150px;
}

.controls .search-input,
.controls .sort-select,
.controls .filter-select {
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: #fff;
  transition: border-color var(--transition-speed);
}

.controls .search-input:focus,
.controls .sort-select:focus,
.controls .filter-select:focus {
  border-color: var(--color-primary);
}

.controls .new-project-button,
.controls .export-button {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  transition: background-color var(--transition-speed);
}

.controls .new-project-button:hover,
.controls .export-button:hover {
  background-color: #2563eb;
}

.controls .new-project-button svg,
.controls .export-button svg {
  font-size: 1rem;
}

/* Charts */
.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.chart-card {
  flex: 1 1 250px;
  background-color: var(--color-card-background);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 4px var(--color-shadow);
}

.chart-card h3 {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--color-text);
}

/* Spinner */
.spinner {
  border: 6px solid var(--color-background);
  border-top: 6px solid var(--color-primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error and No Projects Message */
.error-message,
.no-projects-message {
  text-align: center;
  font-size: 1.25rem;
  color: var(--color-text-muted);
  margin-top: 50px;
}

.error-message {
  color: var(--color-danger);
}

/* Projects Table */
.projects-table {
  width: 100%;
  overflow-x: auto;
}

.projects-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--color-card-background);
  box-shadow: 0 1px 4px var(--color-shadow);
  border-radius: 8px;
  overflow: hidden;
}

.projects-table th,
.projects-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.projects-table th {
  background-color: var(--color-primary);
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.projects-table tr:nth-child(even) {
  background-color: #fafafa;
}

.projects-table tr:hover {
  background-color: #f1f1f1;
}

.projects-table td .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.projects-table td .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.projects-table td .slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color var(--transition-speed);
  border-radius: 20px;
}

.projects-table td .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: transform var(--transition-speed);
  border-radius: 50%;
}

.projects-table td input:checked + .slider {
  background-color: var(--color-secondary);
}

.projects-table td input:checked + .slider:before {
  transform: translateX(20px);
}

.projects-table .action-button {
  background-color: transparent;
  color: var(--color-text-muted);
  margin-right: 0.5rem;
  font-size: 1.25rem;
  transition: color var(--transition-speed);
}

.projects-table .action-button:hover {
  color: var(--color-primary);
}

.projects-table .action-button.view {
  color: var(--color-secondary);
}

.projects-table .action-button.view:hover {
  color: #219653;
}

.projects-table .action-button.edit {
  color: var(--color-accent);
}

.projects-table .action-button.edit:hover {
  color: #d17d0f;
}

.projects-table .action-button.delete {
  color: var(--color-danger);
}

.projects-table .action-button.delete:hover {
  color: #c0392b;
}

/* Expand Button */
.projects-table .expand-button {
  background-color: transparent;
  color: var(--color-primary);
  font-size: 1.25rem;
  cursor: pointer;
  transition: color var(--transition-speed);
}

.projects-table .expand-button:hover {
  color: #2563eb;
}

/* Canvases Table */
.canvases-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.canvases-table th,
.canvases-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.canvases-table th {
  background-color: var(--color-secondary);
  color: #fff;
}

.canvases-table tr:nth-child(even) {
  background-color: #f9fafb;
}

.canvases-table tr:hover {
  background-color: #f1f1f1;
}

.canvases-table .action-button {
  font-size: 1rem;
}

/* Shared Canvases Section */
.shared-canvases-section {
  margin-top: 3rem;
}

.shared-canvases-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-text);
}

.shared-canvases-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--color-card-background);
  box-shadow: 0 1px 4px var(--color-shadow);
  border-radius: 8px;
  overflow: hidden;
}

.shared-canvases-table th,
.shared-canvases-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.shared-canvases-table th {
  background-color: var(--color-primary);
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.shared-canvases-table tr:nth-child(even) {
  background-color: #fafafa;
}

.shared-canvases-table tr:hover {
  background-color: #f1f1f1;
}

.shared-canvases-table .action-button {
  background-color: transparent;
  color: var(--color-text-muted);
  margin-right: 0.5rem;
  font-size: 1.25rem;
  transition: color var(--transition-speed);
}

.shared-canvases-table .action-button:hover {
  color: var(--color-primary);
}

.shared-canvases-table .action-button.view {
  color: var(--color-secondary);
}

.shared-canvases-table .action-button.view:hover {
  color: #219653;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.5rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: #fff;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.pagination-button:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.pagination-button.active {
  background-color: var(--color-primary);
  color: #fff;
  border-color: var(--color-primary);
}

.pagination-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 128, 237, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn var(--transition-speed) ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal Content */
.modal-content {
  background-color: var(--color-card-background);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 1px 4px var(--color-shadow);
  animation: slideIn var(--transition-speed) ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
  color: var(--color-primary);
}

.modal-content p {
  font-size: 1rem;
  color: var(--color-text);
  margin-bottom: 1.5rem;
}

.modal-content form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.modal-content form input,
.modal-content form textarea,
.modal-content form select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 1rem;
  transition: border-color var(--transition-speed);
}

.modal-content form input:focus,
.modal-content form textarea:focus,
.modal-content form select:focus {
  border-color: var(--color-primary);
}

.modal-content form textarea {
  resize: vertical;
  min-height: 100px;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-actions .cancel-button,
.modal-actions .confirm-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  transition: background-color var(--transition-speed);
}

.modal-actions .cancel-button {
  background-color: var(--color-border);
  color: var(--color-text);
}

.modal-actions .cancel-button:hover {
  background-color: #bdbdbd;
}

.modal-actions .confirm-button {
  background-color: var(--color-primary);
  color: #fff;
}

.modal-actions .confirm-button:hover {
  background-color: #2563eb;
}

/* Footer */
.dashboard-footer {
  background-color: var(--color-card-background);
  padding: 1rem 2rem;
  text-align: center;
  box-shadow: 0 -1px 4px var(--color-shadow);
}

.dashboard-footer p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--color-text-muted);
}

/* Responsive Design */
@media (max-width: 768px) {
  .controls {
    flex-direction: column;
  }

  .controls > * {
    flex: 1 1 100%;
  }

  .dashboard-header,
  .dashboard-footer {
    padding: 1rem;
  }

  .dashboard-main {
    padding: 1rem;
  }

  .modal-content {
    padding: 1.5rem;
  }

  .charts-container {
    flex-direction: column;
  }

  .chart-card {
    flex: 1 1 100%;
  }

  .projects-table th,
  .projects-table td,
  .shared-canvases-table th,
  .shared-canvases-table td {
    padding: 0.75rem 0.5rem;
  }

  .controls .new-project-button,
  .controls .export-button {
    justify-content: center;
    width: 100%;
  }
}
.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  color: #555; /* Default color */
  transition: color 0.3s;
}

.action-button:hover {
  color: #000; /* Color on hover */
}

/* Specific styles for different action buttons */
.action-button.view {
  color: #2f80ed; /* Blue */
}

.action-button.edit {
  color: #f2994a; /* Orange */
}

.action-button.delete {
  color: #eb5757; /* Red */
}
.action-buttons{
  display: flex;
}.project-name-link {
  color: #2f80ed; /* Or any color you prefer */
  cursor: pointer;
  text-decoration: underline;
}
/* Styles for Read More toggle */
.read-more-toggle {
  color: #2f80ed; /* Blue color for the link */
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.read-more-toggle:hover {
  color: #1c5bbf; /* Darker blue on hover */
}