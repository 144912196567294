/* Canvas.css */

/* General Styling */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f2f5;
}

#root {
  height: 100%; /* Ensure the root element takes up the full height */
}

.canvas-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden; /* Changed to 'hidden' to manage scrolling within canvas */
  position: relative;
}

/* Loading and Error Containers */
.loading-container, .error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.canvas-logo {
  height: 60px; /* Adjust the height as needed */
  width: auto;
  transition: opacity 0.3s ease;
}

.canvas-logo:hover {
  opacity: 0.8;
}

/* Toolbar */
.toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
}

.toolbar button, .toolbar .chakra-button {
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  z-index: 200;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.toolbar button:hover, .toolbar .chakra-button:hover {
  background-color: #2D3748;
  color: white;

  transform: translateY(-2px);
}

.toolbar button:disabled, .toolbar .chakra-button:disabled {
  background-color: #A0AEC0;
  cursor: not-allowed;
}

/* Main Content */
.main-content {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

/* Tool Sidebar */
.tool-picker {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #EDF2F7; /* Light gray */
  width: 250px;
  min-width: 200px;
  max-width: 300px;
  overflow-y: auto;
  transition: width 0.3s ease;
  position: relative;
}

.tool-picker.collapsed {
  width: 50px;
}

.tool-picker::-webkit-scrollbar {
  width: 8px;
}

.tool-picker::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 4px;
}

/* Tool */
.tool {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: grab;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.tool:hover {
  background-color: #F7FAFC;
  transform: translateY(-2px);
}

/* Dropped Tool */
.dropped-tool {
  display: inline-block;
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: move;
  position: absolute;
  user-select: none;
  margin: 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease, border 0.2s ease;
  z-index: 10;
}

.dropped-tool:active {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Connection Lines */
.connection-lines {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 5;
}

/* Popup Overlay and Window */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: relative;
  width: 100vw !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 2001;
  animation: fadeIn 0.3s ease-out;
  overflow-y: auto;
}

.popup-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close-button {
  background-color: #E53E3E;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #C53030;
}

/* Smooth Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar Styling for Popup */
.popup::-webkit-scrollbar {
  width: 8px;
}

.popup::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 4px;
}

/* Zoom and Lock Controls */
.zoom-lock-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.zoom-lock-controls button {
  background-color: #3182CE;
  border: none;
  color: white;
  padding: 8px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.zoom-lock-controls button:hover {
  background-color: #2B6CB0;
  transform: scale(1.1);
}

.zoom-lock-controls button:disabled {
  background-color: #A0AEC0;
  cursor: not-allowed;
}

/* Sticky Note Styling */
.sticky-note {
  background-color: #F6E05E; /* Solid Yellow Background */
  border: 1px solid #ECC94B; /* Slightly Darker Yellow Border */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: absolute; /* Ensure sticky notes can be positioned on the canvas */
  user-select: none; /* Prevent text selection during dragging */
}

.sticky-note:hover {
  background-color: #FBD38D; /* Lighter Yellow on Hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.sticky-note-content {
  background-color: #F6E05E; /* Ensure content area has the same solid background */
  padding: 5px;
  border-radius: 4px;
  cursor: text;
}

.sticky-note-textarea {
  background-color: #FAF089; /* Slightly Lighter Yellow for Textarea */
  border: 1px solid #D69E2E; /* Border Color */
  border-radius: 4px;
  width: 100%;
  resize: none; /* Prevent resizing */
}

/* Prevent Text Selection During Panning */
.canvas.panning, .canvas.grab {
  user-select: none;
}

/* Cursor Styles for Panning */
.canvas {
  /* Transition for smooth cursor changes */
  transition: cursor 0.2s ease;
}

.canvas.panning {
  cursor: grabbing;
}

.canvas.grab {
  cursor: grab;
}

/* Ensuring the canvas-content fills appropriately */
.canvas-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 5000px;
  height: 5000px;
  background-size: 50px 50px; /* GRID_SIZE = 50px */
  background-image:
    linear-gradient(to right, #eee 1px, transparent 1px),
    linear-gradient(to bottom, #eee 1px, transparent 1px);
}
